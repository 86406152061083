<template>
  <div class="header">
    <div class="logo">
      <img :src="require('/src/assets/img/logo.png')" />
    </div>
    <div class="tab">
      <div
        class="mybutton home"
        :class="{ sel: $route.path === '/home' }"
        @click="toRouter('/home')"
      >
        HOME
      </div>
      <div
        class="mybutton about"
        :class="{ sel: $route.path === '/about' }"
        @click="toRouter('/about')"
      >
        ABOUT US
      </div>
      <div
        class="mybutton contact"
        :class="{ sel: $route.path === '/contact' }"
        @click="toRouter('/contact')"
      >
        CONTACT US
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  padding: 0px 2.19vw 0px 1.5vw;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  .logo {
    margin-top: 1.9vh;
    img {
      height: 7.87vh;
    }
  }
  .tab {
    flex: 1;
    display: flex;
    justify-content: flex-end;
     margin-top: 3vh;
    > div {
      margin-left: 5.21vw;
      font-size: 2.96vh;
    }
    .sel {
      font-size: 4.17vh;
    }
    .home.sel {
      color: #f7b500;
    }
    .about.sel {
      color: #32d4d0;
    }
  }
}
</style>
