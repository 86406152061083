import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout/Layout.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  // 登录
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("../views/Home/index.vue"),
      },
      {
        path: "/service",
        component: () => import("../views/Service/index.vue"),
      },
      {
        path: "/service/smart-traffic",
        component: () => import("../views/Service/Smart-Traffic/index.vue"),
      },
      {
        path: "/service/green-energy-ev",
        component: () => import("../views/Service/Green-Energy-EV/index.vue"),
      },
      {
        path: "/service/auto-vehicle",
        component: () => import("../views/Service/Auto-Vehicle/index.vue"),
      },
      {
        path: "/odc",
        component: () => import("../views/ODC/index.vue"),
      },
      {
        path: "/about",
        component: () => import("../views/About/index.vue"),
      },
      {
        path: "/about-team",
        component: () => import("../views/About/our-team.vue"),
      },
      {
        path: "/contact",
        component: () => import("../views/Contact/index.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  routes,
});
/* 路由守卫 */
// router.beforeEach((to, from, next) => {
//   let isLogin = localStorage.getItem('token') ? true : false;

//   if (isLogin) {
//     next();
//   } else {
//     if (to.path === "/login") {
//       next();
//     } else {
//       next({ path: "/login" });
//     }
//   }
// });
export default router;
