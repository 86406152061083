<template>
  <div class="layout">
    <Header></Header>
    <div class="content-div">
      <router-view />
    </div>
    <div class="bottom">Copyright &#169 2022 J&F Mobility Pte.Ltd. - All Rights Reserved. <a href=" " target="_blank" style="color: #fff;">蜀ICP备2023010898号-2</a></div>
  </div>
</template>

<script>
import Header from "./Header.vue";
export default {
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.layout {
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .content-div {
    flex: 1;
    > div {
      padding: 110px 0px 70px 0px;
    }
  }
  .bottom {
    height: 6.4vh;
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    opacity: 0.55;
    background: #000000;
    border: 0.75px solid #000000;
    font-size: 18px;
    font-family: Helvetica, Helvetica-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 21.75px;
    letter-spacing: 1.13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 22.5px;
  }
}
</style>
