import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import vuescroll from 'vuescroll';
import sha256 from 'js-sha256';
import "./api/moment"
import 'element-ui/lib/theme-chalk/index.css';
const echarts = require('echarts');
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.use(vuescroll);
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue;
import './assets/css/reset.css'
import './assets/js/html2canvas.min'
let eventBus = new Vue;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$sha256 = sha256;
//import './directive.js'
import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('zh-cn')


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
